import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import Divider from '../../Divider';
import Translations from '../../../helpers/Translations';
import {connect} from 'react-redux';
import {openDashboardNewsTestingDialog} from '../../../actions/DialogActions';
import Constants from '../../../helpers/Constants';
import Skeletons from '../../../helpers/Skeletons';
import loginManagerModelSkeleton from '../../../skeletons/models/loginManagerModelSkeleton';

class DashboardProfesiaNews extends Component
{
    render = () => {
        const {loginManagerModel: {userTestings}} = this.props;

        return (
            <>
                <div className="flex justify between">
                    <h5 className="flex title">
                        <Icon className="icon-bulb" size="mini"/>
                        <div className="content">
                            {Translations.getStatic('whatIsNewOnProfesia')}
                        </div>
                    </h5>
                    {
                        userTestings.find(userTesting => userTesting.testingType === Constants.TESTING_TYPE_DASHBOARD_NEWS) === undefined
                        && (
                            <span onClick={this.props.openDashboardNewsTestingDialog} className="primary-link">{Translations.getStatic('allNews')}</span>
                        )
                    }
                </div>

                <Divider hidden className="size_16"/>

                <div className="card card-news-feed">
                    <div className="feed">
                        <div className="meta">11.5.2023<span> | </span>Analýza</div>
                        <p className="txt-bold">Ponúka Slovensko flexibilnú prácu pre matky?</p>
                    </div>
                    <div className="feed">
                        <div className="meta">28.4.2023<span> | </span>Rady a tipy</div>
                        <p className="txt-bold">Ako sa s ľudmi baviť v práci o peniazoch? </p>
                    </div>
                    <div className="feed">
                        <div className="meta">27.4.2023<span> | </span>CSR</div>
                        <p className="txt-bold">Obraz akceptácie rozmanitosti ľudí na slovenských pracoviskách</p>
                    </div>
                </div>
            </>
        );
    };
}

DashboardProfesiaNews.propTypes = {
    loginManagerModel: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
});

const mapDispatchToProps = {
    openDashboardNewsTestingDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProfesiaNews);



