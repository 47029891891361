import loggedUserTypeSkeleton from '../loggedUserTypeSkeleton';

const dashboardModelSkeleton = {
    activeOffers: [],
    archivedOffers: [],
    activeServices: [],
    unpaidInvoices: [],
    loggedUser: loggedUserTypeSkeleton,
    users: [],
    candidatesInNewStage: [],
    newCandidatesTotalCount: 0,
};

export default dashboardModelSkeleton;
