import { Languages } from '@profesia/mark-offer-form/i18n';
import Url from '../../../helpers/Url';

type Response = {
    suggestions: string[];
};

const getJobTitleAiSuggestions = async (
    userInput: string,
    locale: Languages
): Promise<string[]> => {
    const response = await fetch(`${Url.MARK_SERVER_PREFIX}/api/v1/ai/generate/job-title`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userInput,
            language: locale,
        }),
    });

    if (response.status === 403 || response.status === 401) {
        Url.redirectTo(Url.buildLoginLink());

        throw new Error('Unauthorized');
    }

    const responseJson: Response = await response.json();

    return responseJson.suggestions;
};

export default getJobTitleAiSuggestions;
