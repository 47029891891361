import { GetOtherSkillsResponse } from '@profesia/mark-offer-form/services';
import Url from '../../../helpers/Url';

const remapSkills = (otherSkills: OptionItem[]) =>
    otherSkills.map(({ id, name }) => ({ id: id.toString(), value: name }));

type OptionItem = {
    id: number;
    name: string;
};

type Response = OptionItem[];

const getOtherSkills = async (otherSkill: string): Promise<GetOtherSkillsResponse> => {
    const response = await fetch(  
        `${Url.MARK_SERVER_PREFIX}/api/v1/codebook/skill?locale=sk&query=${otherSkill}`,  
        {  
            headers: {  
                accept: 'application/json',  
            },  
            method: 'GET',  
        }  
    );

    if (response.status === 403 || response.status === 401) {
        Url.redirectTo(Url.buildLoginLink());

        throw new Error('Unauthorized');
    }

    const responseJson: Response = await response.json();

    return remapSkills(responseJson);
};

export default getOtherSkills;
