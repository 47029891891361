const headerModelMenuSkeleton = [
    {
        title: 'home',
        showInHeader: true,
        showInSidebar: 'mobile',
        action: 'dashboard',
        outsideMark: true,
    },
    {
        title: 'advertising',
        showInHeader: true,
        showInSidebar: 'mobile',
        submenu: [
            {
                title: 'addOffer',
                url: '/add_offer.php',
            },
            {
                title: 'companyListOffers',
                url: '/company_details.php?action=list_active_offers',
            },
            {
                title: 'companyOfferStats',
                url: '/company_details.php?action=offer_stats',
            },
        ],
    },
    {
        action: 'jobs',
        title: 'candidates',
        showInHeader: true,
        showInSidebar: 'mobile',
        submenu: [
            {
                action: 'jobs',
            },
            {
                action: 'search',
                title: 'searchForCandidate',
            },
            {
                action: 'statistics',
                visible: true,
                allowedInPackage: {
                    serviceId: 2603,
                    name: 'PRO',
                },
            },
            {
                title: 'help',
                url: '/mark/help',
            },
        ],
        class: 'active',
    },
    {
        title: 'databaseCV',
        showInHeader: true,
        showInSidebar: 'mobile',
        submenu: [
            {
                title: 'searchCV',
                url: '/cvdb/list',
            },
            {
                title: 'filtersCV',
                url: '/cvdb/filter/list',
            },
            {
                title: 'cVCreditsStats',
                url: '/company_details.php?action=cvseen_info',
            },
        ],
    },
    {
        title: 'pricingList',
        showInHeader: true,
        showInSidebar: false,
        url: '/cennik-sluzieb#candidatesmanagement',
    },
    {
        title: 'services',
        showInHeader: false,
        showInSidebar: true,
        submenu: [
            {
                title: 'servicesOverview',
                url: '/company_details.php?action=service_info',
            },
            {
                title: 'companyInvoices',
                url: '/company_details.php?action=list_unpaid_invoices',
            },
        ],
    },
    {
        title: 'settings',
        showInHeader: false,
        showInSidebar: true,
        submenu: [
            {
                title: 'loginData',
                url: '/registracia-firmy?mode=52',
            },
            {
                title: 'companyData',
                url: '/company_details.php?action=full_info',
            },
            {
                title: 'contactPersons',
                url: '/company_details.php?action=contact_info',
            },
            {
                title: 'markSettings',
                action: 'settings',
            },
            {
                title: 'cookiesSettings',
                id: 'change-consent',
            },
        ],
    },
];

export default headerModelMenuSkeleton;
