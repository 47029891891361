import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import Translations from '../../../helpers/Translations';
import {connect} from 'react-redux';
import {openDashboardStatisticsTestingDialog} from '../../../actions/DialogActions';
import Constants from '../../../helpers/Constants';
import Skeletons from '../../../helpers/Skeletons';
import loginManagerModelSkeleton from '../../../skeletons/models/loginManagerModelSkeleton';
import Divider from '../../Divider';

class DashboardStatistics extends Component
{
    render = () => {
        const {loginManagerModel: {userTestings}} = this.props;

        return (
            <>
                <div className="flex justify between">
                    <h5 className="flex title">
                        <Icon className="icon-trend" size="mini"/>
                        <div className="content">
                            {Translations.getStatic('ourMarketData')}
                        </div>
                    </h5>
                    {
                        userTestings.find(userTesting => userTesting.testingType === Constants.TESTING_TYPE_DASHBOARD_STATISTICS) === undefined
                        && (
                            <span onClick={this.props.openDashboardStatisticsTestingDialog} className="primary-link">{Translations.getStatic('moreInfo')}</span>
                        )
                    }
                </div>

                <Divider hidden className="size_16"/>

                <div className="card card-trend-dashboard">
                    <div className="box top left">
                        <h5>{Translations.getStatic('unemploymentRate')}</h5>
                        <div className="number">5,61%</div>
                        <div className="meta">
                            <span className="trend down">-15,91%</span>
                            {Translations.getStatic('yearly')}
                        </div>
                    </div>
                    <div className="box top right">
                        <h5>{Translations.getStatic('averageSalaryInSlovakia')}</h5>
                        <div className="number">1 463&nbsp;&euro;</div>
                        <div className="meta">
                            <span className="trend up">+9,97%</span>
                            {Translations.getStatic('yearly')}
                        </div>
                    </div>
                    <div className="box bottom left">
                        <h5>{Translations.getStatic('numberOfCvs')}</h5>
                        <div className="number">67 650</div>
                        <div className="meta">
                            <span className="trend up">+20,32%</span>
                            {Translations.getStatic('yearly')}
                        </div>
                    </div>
                    <div className="box bottom right">
                        <h5>{Translations.getStatic('averageNumberOfReactions')}</h5>
                        <div className="number">12</div>
                        <div className="meta">
                            <span className="trend up">+33,96%</span>
                            {Translations.getStatic('yearly')}
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

DashboardStatistics.propTypes = {
    loginManagerModel: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton),
});

const mapDispatchToProps = {
    openDashboardStatisticsTestingDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardStatistics);



