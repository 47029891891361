import Channel from './Channel';
import Language from './Language';
import Translations from './Translations';
import Constants from './Constants';
import LmcCookieConsentManager from '@lmc-eu/cookie-consent-manager';
import '@lmc-eu/cookie-consent-manager/LmcCookieConsentManager.min.css';
import { CommonObjectType } from '../../interfaces/CommonTypes';

class CookieConsentManager {
    static init(): void {
        window.cc = LmcCookieConsentManager('profesia.sk', this.getData());
    }

    static getData(): CommonObjectType {
        const policyUri: string =
            Channel.getChannel() === Channel.CHANNEL_CZ
                ? 'ochrana-osobnich-udaju'
                : 'ochrana-osobnych-udajov';

        const translationOverrides: CommonObjectType = {};

        Constants.ALLOWED_LANGUAGES.forEach((language) => {
            const privacyPolicyUrl: string = ['', language, policyUri].join('/');

            translationOverrides[language] = {
                settingsModalMoreInfo: Translations.getStatic(
                    'cookieConsentManagerMoreInfo',
                    language
                ).replace('%url%', privacyPolicyUrl),
            };
        });

        return {
            defaultLang: Language.get(),
            autodetectLang: true,
            companyNames: ['Profesia'],
            translationOverrides,
        };
    }
}

export default CookieConsentManager;
