import {UserType} from '../../../interfaces/ModelTypes';
import UserRoleTypeSkeleton from './userRoleTypeSkeleton';

const userTypeSkeleton: UserType = {
    canUserMakeOrders: false,
    city: '',
    companyId: 1,
    contactEmail: '',
    defaultAutoAnswerId: 0,
    defaultJobViewId: 0,
    defaultPublicNotes: 0,
    email: '',
    firstname: '',
    hasActiveEventForwarding: false,
    id: 1,
    name: '',
    phone: '',
    profesiaUserId: 1,
    street: '',
    surname: '',
    title: '',
    userRole: UserRoleTypeSkeleton,
    zip: '',
};

export default userTypeSkeleton;
