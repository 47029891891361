import { Dispatch, Action } from 'redux'; 
import { FormT } from '@profesia/mark-offer-form/components/content/form/types';
import { transformData } from './transformedData';
import {UPDATE_POSITION_DATA, UPDATE_POSITION_SUCCESS, UPDATE_POSITION_FAILURE} from '../../../actions/CreateJobActions';
import Url from '../../../helpers/Url';

const updateForm = async (data: FormT, id: boolean, dispatch: Dispatch<Action>): Promise<boolean> => {
    const transformedData = transformData(data);
    transformedData.id = id;

    dispatch({ type: UPDATE_POSITION_DATA });

    const response = await fetch(`${Url.MARK_SERVER_PREFIX}/api/v1/position`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(transformedData),
    });

    if (!response.ok) {
        dispatch({ type: UPDATE_POSITION_FAILURE });
        
        throw new Error('Server error: ' + response.status);
    }

    const jsonResponse: { id: number } = await response.json();

    dispatch({ type: UPDATE_POSITION_SUCCESS, payload: jsonResponse });

    return true;
};

export default updateForm;