export const REQUEST_CREATE_JOB_MODEL         = 'REQUEST_CREATE_JOB_MODEL';
export const RECEIVE_CREATE_JOB_MODEL         = 'RECEIVE_CREATE_JOB_MODEL';
export const ENABLE_STEP                      = 'ENABLE_STEP';
export const ACTIVATE_STEP                    = 'ACTIVATE_STEP';
export const ENABLE_QUESTIONNAIRE             = 'ENABLE_QUESTIONNAIRE';
export const DISABLE_QUESTIONNAIRE            = 'DISABLE_QUESTIONNAIRE';
export const CREATE_JOB                       = 'CREATE_JOB';
export const CREATE_JOB_SUCCESS               = 'CREATE_JOB_SUCCESS';
export const CREATE_JOB_FAILURE               = 'CREATE_JOB_FAILURE';
export const UPDATE_POSITION_DATA             = 'UPDATE_POSITION_DATA';
export const UPDATE_POSITION_SUCCESS          = 'UPDATE_POSITION_SUCCESS';
export const UPDATE_POSITION_FAILURE          = 'UPDATE_POSITION_FAILURE';
export const REMOVE_CONCEPT                   = 'REMOVE_CONCEPT';
export const REMOVE_CONCEPT_SUCCESS           = 'REMOVE_CONCEPT_SUCCESS';
export const REMOVE_CONCEPT_FAILURE           = 'REMOVE_CONCEPT_FAILURE';
export const ENABLE_AUTO_ANSWER               = 'ENABLE_AUTO_ANSWER';
export const DISABLE_AUTO_ANSWER              = 'DISABLE_AUTO_ANSWER';
export const UPDATE_JOB_DATA                  = 'UPDATE_JOB_DATA';
export const CREATE_QUESTIONNAIRE             = 'CREATE_QUESTIONNAIRE';
export const CREATE_QUESTIONNAIRE_SUCCESS     = 'CREATE_QUESTIONNAIRE_SUCCESS';
export const CREATE_QUESTIONNAIRE_FAILURE     = 'CREATE_QUESTIONNAIRE_FAILURE';
export const CREATE_AUTO_ANSWER               = 'CREATE_AUTO_ANSWER';
export const CREATE_AUTO_ANSWER_SUCCESS       = 'CREATE_AUTO_ANSWER_SUCCESS';
export const CREATE_AUTO_ANSWER_FAILURE       = 'CREATE_AUTO_ANSWER_FAILURE';
export const CREATE_PIPELINE_TEMPLATE         = 'CREATE_PIPELINE_TEMPLATE';
export const CREATE_PIPELINE_TEMPLATE_SUCCESS = 'CREATE_PIPELINE_TEMPLATE_SUCCESS';
export const CREATE_PIPELINE_TEMPLATE_FAILURE = 'CREATE_PIPELINE_TEMPLATE_FAILURE';
export const CLEAR_CREATE_JOB_DATA            = 'CLEAR_CREATE_JOB_DATA';

export const requestCreateJobModel = (jobId = null) => ({
    type: REQUEST_CREATE_JOB_MODEL,
    meta: {
        requestRoute: 'v1/job/model/create/' + (jobId === null ? 0 : jobId),

        onRequestSuccess: RECEIVE_CREATE_JOB_MODEL,
    },
});

export const activateStep = (stepAction) => ({
    type: ACTIVATE_STEP,
    payload: {
        stepAction,
    },
});

export const enableStep = (stepAction) => ({
    type: ENABLE_STEP,
    payload: {
        stepAction,
    },
});

export const enableQuestionnaire = () => ({
    type: ENABLE_QUESTIONNAIRE,
});

export const disableQuestionnaire = () => ({
    type: DISABLE_QUESTIONNAIRE,
});

export const createJob = (formData) => ({
    type: CREATE_JOB,
    meta: {
        requestRoute: 'v1/job/action/create',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CREATE_JOB_SUCCESS,
        onRequestFailure: CREATE_JOB_FAILURE,
    },
});

export const removeConcept = (jobId) => ({
    type: REMOVE_CONCEPT,
    meta: {
        requestRoute: 'v1/job/action/set-concept-as-invisible/' + jobId,
        requestMethod: 'POST',

        onRequestSuccess: REMOVE_CONCEPT_SUCCESS,
        onRequestFailure: REMOVE_CONCEPT_FAILURE,
    },
});

export const enableAutoAnswer = () => ({
    type: ENABLE_AUTO_ANSWER,
});

export const disableAutoAnswer = () => ({
    type: DISABLE_AUTO_ANSWER,
});

export const updateJobData = (jobData) => ({
    type: UPDATE_JOB_DATA,
    payload: {
        jobData,
    },
});

export const createQuestionnaire = (formData) => ({
    type: CREATE_QUESTIONNAIRE,
    meta: {
        requestRoute: 'v1/questionnaire/action/create',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CREATE_QUESTIONNAIRE_SUCCESS,
        onRequestFailure: CREATE_QUESTIONNAIRE_FAILURE,
    },
});

export const createPipelineTemplate = (formData) => ({
    type: CREATE_PIPELINE_TEMPLATE,
    meta: {
        requestRoute: 'v1/pipeline-template/action/create',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CREATE_PIPELINE_TEMPLATE_SUCCESS,
        onRequestFailure: CREATE_PIPELINE_TEMPLATE_FAILURE,
    },
});

export const createAutoAnswer = (formData) => ({
    type: CREATE_AUTO_ANSWER,
    meta: {
        requestRoute: 'v1/auto-answer/action/create',
        requestMethod: 'POST',
        requestBody: formData,

        onRequestSuccess: CREATE_AUTO_ANSWER_SUCCESS,
        onRequestFailure: CREATE_AUTO_ANSWER_FAILURE,
    },
});

export const clearCreateJobData = () => ({
    type: CLEAR_CREATE_JOB_DATA,
});
