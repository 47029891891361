import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dropdown} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {ButtonUnsuitable} from '@profesia/adamui/components/common/button';
import DropdownButton from '@profesia/adamui/components/common/dropdown/DropdownButton';
import Container from '../../Container';
import Item from '../../Item';
import Image from '../../Image';
import Divider from '../../Divider';
import Heading from '../../Heading';
import Icon from '../../Icon';
import {changeCandidateStage} from '../../../actions/JobDetailActions';
import Constants from '../../../helpers/Constants';
import Translations from '../../../helpers/Translations';
import {openModal} from '../../../actions/ModalActions';
import candidateHired from '../../../../img/icons/candidate-hired.svg';
import CandidateHelper from '../../../helpers/components/CandidateHelper';
import {removeFromCurrentlyAcceptedCandidates, setCurrentlyAcceptedCandidates} from '../../../actions/CurrentlyAcceptedCandidatesActions';
import Url from '../../../helpers/Url';
import CandidatesReducer from '../../../helpers/generalReducers/CandidatesReducer';
import Track from '../../../helpers/Track';
import {TRACK_NAME_CHANGE_STATUS, TRACK_LOCATION_DETAIL, TRACK_FUNNEL_NAME_THUMB_DOWN, TRACK_FUNNEL_TYPE_EXCLUDE_CANDIDATE, TRACK_FUNNEL_NAME_DROPDOWN_MENU} from '../../../helpers/ConstantsTrack';

class CandidateSidebarStage extends Component
{
    handleDropdownChange = (candidateId, stageId) => {
        const formData = new FormData();

        formData.append('stageId', stageId);
        formData.append('candidateId', candidateId);

        const stage = this.props.stages.find(stage => parseInt(stageId) === parseInt(stage.stageId));

        this.props.changeCandidateStage(formData, candidateId, stage, 0, CandidatesReducer.getReducerType());

        if (parseInt(stageId) === Constants.CANDIDATE_STAGE_ACCEPTED_ID) {
            this.props.setCurrentlyAcceptedCandidates(this.props.jobId, [candidateId], Url.getUrl());
        } else {
            this.props.removeFromCurrentlyAcceptedCandidates([candidateId]);
        }

        Track.event(TRACK_LOCATION_DETAIL, TRACK_NAME_CHANGE_STATUS);
    };

    handleRefusalReasonDropdownChange = reasonId => {
        this.props.openModal('RefuseCandidateWithEmailModal', {
            candidateIds: [this.props.candidateId],
            refusalReasonId: reasonId,
        });

        Track.funnel(2, TRACK_FUNNEL_NAME_DROPDOWN_MENU, TRACK_FUNNEL_TYPE_EXCLUDE_CANDIDATE);
    };

    handleRefusalReasonDropdownOpen = () => {
        Track.funnel(1, TRACK_FUNNEL_NAME_THUMB_DOWN, TRACK_FUNNEL_TYPE_EXCLUDE_CANDIDATE);
    };

    render() {
        const {stage, stages, stateId, candidateId, refusalReasons, candidateRefusalReason, hasActiveFreeSubscription} = this.props;

        const options = stages
            .map(stage => ({
                key: stage.stageId,
                id: stage.stageId,
                label: Translations.getStage(stage),
            }));

        const MARK_2604 = Constants.MARK_2604 && hasActiveFreeSubscription;

        if (stateId === Constants.CANDIDATE_STATE_REFUSED) {
            options.push(
                {key: 'noSelecting', id: null, label: '', className: 'item-hidden'},
                {id: 'backToActiveSelectionProcess', label: Translations.getStatic('backToActiveSelectionProcess')},
            );

            return (
                <Container>
                    <Heading as="h3" textAlign="center" className="txt-red">
                        {Translations.getStatic('refusedCandidate')}
                        <Divider className="size_4" hidden/>
                        <Heading.Subheader className="txt-red txt-medium padding-top_none">
                            {CandidateHelper.getRefusalReason(candidateRefusalReason)}
                        </Heading.Subheader>
                        <Divider className="size_16" hidden/>
                        <Heading.Subheader>
                            {Translations.getStatic('candidateRefuseFromStage')}<br/>{Translations.getStage(stage)}
                        </Heading.Subheader>
                        <DropdownButton
                            defaultValue={'backToActiveSelectionProcess'}
                            type="medium"
                            styleVariant="secondary"
                            options={options}
                            onChange={e => this.handleDropdownChange(candidateId, e.target.value)}
                        />
                        <Divider className="hidden size_16 multiple"/>
                        <Divider/>
                    </Heading>
                </Container>
            );
        }

        return (
            <>
                {
                    stage.id === Constants.CANDIDATE_STAGE_ACCEPTED_ID
                        ? <Image className="auto" src={candidateHired}/>
                        : null
                }

                <div className="ui items unstackable">
                    <Item className="block-flex_center">
                        <Icon className="icon-stage_small" size="mini"/>
                        <Item.Content className={'flex'}>
                            <DropdownButton
                                className={`dropdown-button_primary${MARK_2604 ? ' change-stage-button' : ''}`}
                                options={options}
                                type="medium"
                                value={stage.id}
                                onChange={e => this.handleDropdownChange(candidateId, e.target.value)}
                            />

                            {
                                MARK_2604
                                    ? (
                                        <ButtonUnsuitable
                                            className="refusal-button"
                                            onClick={() => this.handleRefusalReasonDropdownChange(Constants.REFUSAL_REASON_UNSUITABLE_ID)}
                                        >
                                            {Translations.getStatic('unsuitable')}
                                        </ButtonUnsuitable>
                                    )
                                    : (
                                        <Dropdown
                                            className="icon reject-button"
                                            direction="left"
                                            icon={{className: 'icon-reject_small'}}
                                            trigger={<Icon className="dropdown icon"/>}
                                            onOpen={this.handleRefusalReasonDropdownOpen}
                                        >
                                            <Dropdown.Menu>
                                                {
                                                    refusalReasons.map(reason => (
                                                        <Dropdown.Item
                                                            key={reason.id}
                                                            text={Translations.getStatic(reason.refusalReason)}
                                                            onClick={() => this.handleRefusalReasonDropdownChange(reason.id)}
                                                        />
                                                    ))
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )
                            }
                        </Item.Content>
                    </Item>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    changeCandidateStage,
    openModal,
    setCurrentlyAcceptedCandidates,
    removeFromCurrentlyAcceptedCandidates,
};

CandidateSidebarStage.propTypes = {
    changeCandidateStage: PropTypes.func.isRequired,
    stage: PropTypes.object.isRequired,
    stages: PropTypes.array.isRequired,
    refusalReasons: PropTypes.array.isRequired,
    stateId: PropTypes.number.isRequired,
    candidateId: PropTypes.number.isRequired,
    hasActiveFreeSubscription: PropTypes.bool.isRequired,
};

export default connect(null, mapDispatchToProps)(CandidateSidebarStage);
