import {ActivePackageType} from '../../../interfaces/ModelTypes';

const activePackageTypeSkeleton: ActivePackageType = {
    serviceId: 2600,
    companyId: 1,
    id: 1,
    monthly: false,
    orderId: 1,
    prolong: 0,
    serviceName: '',
    userId: 1,
    validFrom: '2024-01-01',
    validTill: '2025-01-01',
};

export default activePackageTypeSkeleton;
