import { GetCodebooksResponse } from '@profesia/mark-offer-form/services';
import { Languages } from '@profesia/mark-offer-form/i18n';
import Url from '../../../helpers/Url';
import { remapOptionItem, remapPositions } from './helpers';
import { ApiResponse } from './types';

const getCodebooks = async (locale: Languages): Promise<GetCodebooksResponse> => {
    const response = await fetch(`${Url.MARK_SERVER_PREFIX}/api/v1/codebook?locale=${locale}`, {
        headers: {
            accept: 'application/json',
        },
        method: 'GET',
    });

    if (response.status === 403 || response.status === 401) {
        Url.redirectTo(Url.buildLoginLink());

        throw new Error('Unauthorized');
    }

    const responseJson: ApiResponse = await response.json();

    return {
        languages: {
            favourites: remapOptionItem(responseJson.languages.favourites),
            others: remapOptionItem(responseJson.languages.others),
        },
        regions: remapOptionItem(responseJson.regions),
        jobPositions: remapPositions(responseJson.positions),
    };
};

export default getCodebooks;
