import { FormT, SalaryFixed, SalaryRange, Salary, SalaryCommon } from '@profesia/mark-offer-form/components/content/form/types'
import { SuitableFor, LanguageRequirements } from '@profesia/mark-offer-form/codebooks';
import { Position, Skill, Region, LanguageSkill } from './types';
  
export const reverseTransformData = (data: any): FormT => {    
    const salary = data.salary;  
  
    let salaryObject: Salary | null = null;  
  
    if (salary) {  
        salaryObject = salary.maximumValue === null ? {  
            type: 'fixed',  
            value: Number(salary.minimumValue),  
            currency: `${salary.currencyId}`,  
            period: `${salary.salaryPeriodId}`,  
            otherInformation: salary.additionalInfo ?? null,  
        } as SalaryFixed & SalaryCommon : {  
            type: 'range',  
            from: Number(salary.minimumValue),  
            to: Number(salary.maximumValue),  
            showTo: salary.publishMaximumValue,  
            currency: `${salary.currencyId}`,  
            period: `${salary.salaryPeriodId}`,  
            otherInformation: salary.additionalInfo ?? null,  
        } as SalaryRange & SalaryCommon;  
    } 

    return {  
        form: {  
            jobTitle: data.title,  
            language: data.language,  
            jobPositions: data.positions.map((position: Position) => ({  
                id: position.positionId.toString(),  
                categoryId: position.categoryId.toString(),  
            })),  
            employmentTypes: data.jobTypes,  
            jobDescription: data.description,  
            educationLevel: data.educationLevel.toString(),  
            positionPlace: {  
                type: data.workplaceTypeId,  
                address: data.googlePlaceId ? [{  
                    value: data.place,  
                    id: data.googlePlaceId  
                }] : [],  
                startDate: data.startDate,  
                regions: data.regions?.map((region: Region) => ({  
                    id: region.regionId.toString(),  
                })),  
            },  
            skills: {  
                others: data.skills.map((item: Skill) => ({  
                    id: item.skillId?.toString(),  
                    level: item.levelId?.toString(),  
                    value: item.skillName,  
                })),  
                personal: data.personalityRequirements,  
                drivingLicence: data.drivingLicences,  
                experienceAtPosition: data.experienceInField,  
                yearsOfExperience: data.experienceYears,  
            },  
            benefits: data.benefits,  
            educationSpecialization: data.educationSpecialization,  
            offerSuitableFor: [  
                ...(data.refugeeFriendly ? [SuitableFor.Refugees] : []),  
                ...(data.suitableForHighSchoolStudent ? [SuitableFor.HighSchoolStudents] : []),  
                ...(data.suitableForUniversityStudent ? [SuitableFor.UniversityStudents] : []),  
                ...(data.validForGraduate ? [SuitableFor.Graduates] : []),  
            ],  
            languages: {  
                required: data.allLanguagesRequired ? LanguageRequirements.All : LanguageRequirements.All,  
                items: data.languageSkills.map((item: LanguageSkill) => ({  
                    id: item.languageId.toString(),  
                    level: item.levelId.toString(),  
                })),  
            },  
            salary: salaryObject, 
        },  
    };  
};  