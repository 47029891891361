import {createStore, combineReducers, applyMiddleware} from 'redux';

import loggerMiddleware from '../middlewares/LoggerMiddleware';
import fetchMiddleware from '../middlewares/FetchMiddleware';
import fetchCandidatesInChunksMiddleware from '../middlewares/FetchCandidatesInChunksMiddleware';
import postChunksMiddleware from '../middlewares/PostChunksMiddleware';
import toastMessagesMiddleware from '../middlewares/ToastMessagesMiddleware';
import authenticationMiddleware from '../middlewares/AuthenticationMiddleware';

import {sidebarHeaderLogic} from '../reducers/SidebarHeaderReducers';
import {sidebarEditPanelLogic} from '../reducers/SidebarReducers';
import {dragAndDropLogic} from '../reducers/DnDReducers';
import {modalLogic, modalSecondLogic, noModalLogic, noModalSecondLogic, noModalDashboardLogic} from '../reducers/ModalReducers';
import {candidateModel, isFetchingCandidateTag, isFetchingCandidateNote, isFetchingCandidateHistory, isFetchingCandidateInbox} from '../reducers/CandidateReducer';
import {jobsListModel, isFetchingJobsListModel, isFetchingJobsDetails, isRequestingJobsExport} from '../reducers/JobsListReducers';
import {headerModel, jobDetailHeader, backButton, isFetchingHeader} from '../reducers/HeaderReducers';
import {jobModel, isFetchingJobModel, isFetchingGetPositionData, isFetchingSourceTag, isFetchingCandidatesForJob, isUpdatingJobReactionForm, isSavingExternalUser, isRemovingExternalUser, isProcessingRecommendationTag, isApplyingDefaultPipelineTemplateToJob, isApplyingPipelineTemplateToJob, isCheckingStagesForTransform, isProcessingPipelineTemplateForJob, isFetchingCandidateForDetail} from '../reducers/JobDetailReducers';
import {loginState, isFetchingLoginManagerModel, isFetchingLoginManagerModelBasic, loginManagerModel, loginManagerModelBasic, isCheckingCandidateFromEmail, candidateFromEmailCheckResult} from '../reducers/LoginReducers';
import {createJobModel, isFetchingCreateJobModel, isSavingJob} from '../reducers/CreateJobReducers';
import {questionnaireEditorModel, isFetchingQuestionnaireEditorModel} from '../reducers/QuestionnaireEditorReducers';
import {autoAnswerEditorModel, isFetchingAutoAnswerEditorModel} from '../reducers/AutoAnswerEditorReducers';
import {sendEmailModel, previewEmailModel, isFetchingSendEmailModel, isFetchingPreviewEmailModel, isAddingNewColleagueContact, isRemovingColleagueContact, isGettingVideoInterviewData} from '../reducers/SendEmaiReducers';
import {liveSearchModel, isFetchingGlobalSearchModel, globalSearchModel, isFetchingCandidates, isFetchingGlobalSearchData, isFetchingCandidateIds} from '../reducers/GlobalSearchReducers';
import {candidateFilter} from '../reducers/CandidatesFilterReducers';
import {candidateListTable} from '../reducers/CandidatesListTableReducers';
import {createCandidateModel, isFetchingCreateCandidateModel, isSavingCandidate} from '../reducers/CreateCandidateReducers';
import {candidateActionModel, isFetchingCopyCandidateModel, isFetchingCandidatesForAction, isCopyingCandidates, isSendingEmail, isChangingStageOfCandidates, isSchedulingCandidateEvent, isFetchingCandidateEventModel, isDeletingCandidateEvent, isAnonymizingCandidates, isFetchingChangeStageModel, isFetchingRefuseCandidatesModel, isRequestingCandidatesExport, isSavingCandidateGdprAgreement, isSendingCandidatesQuestionnaireRequest, isRemovingCandidateFromTalentPool, isFetchingMoveCandidateModel, isMovingCandidates} from '../reducers/ActionWithCandidateReducers';
import {attachmentAssistantModel, isFetchingAttachmentAssistantModel, isFetchingMultiAttachmentAssistantModel} from '../reducers/AttachmentAssistantReducers';
import {questionnaireResultsModel, isFetchingQuestionnaireResults} from '../reducers/QuestionnaireResultsReducers';
import {templateAssistant} from '../reducers/TemplateAssistantReducers.js';
import {isFetchingTemplatesForDropdownSelector, templatesForDropdownSelector} from '../reducers/TemplateDropdownSelectorReducers.js';
import {settingsModel, isFetchingSettingsModel, isAuthenticatingGoogle, isAuthenticatingMicrosoft, isSettingCalendar, isSynchronizingCalendar, isSettingDefaultJobView, isSettingDefaultPublicNotes, isSettingEventToEmailForwarding} from '../reducers/SettingsReducers';
import {questionnairesSettingsModel, isFetchingQuestionnairesSettings, isProcessingQuestionnaire} from '../reducers/QuestionnairesSettingsReducers';
import {autoAnswersSettingsModel, isFetchingAutoAnswersSettings, isProcessingAutoAnswer} from '../reducers/AutoAnswersSettingsReducers';
import {emailTemplatesSettingsModel, isFetchingEmailTemplatesSettings, isProcessingEmailTemplate} from '../reducers/EmailTemplatesSettingsReducers';
import {companySettingsModel, companyPriceListModel, isFetchingCompanySettingsModel, isFetchingCompanyPriceListModel, isFetchingCompanyExportModel, isModifyingServices, isSavingCompanyCandidatesGdprExpirationSettings} from '../reducers/CompanySettingsReducers';
import {pipelineTemplatesSettingsModel, isFetchingPipelineTemplatesSettings, isProcessingPipelineTemplate, isSettingPipelineToOpenJobs} from '../reducers/PipelineTemplatesSettingsReducers';
import {dashboardModel, isFetchingDashboardModel, isFetchingDashboardJobCandidates, dashboardJobCandidatesFetchingStatus, isFetchingDashboardDataForUser, isClosingJobs, isFetchingMoreEvents} from '../reducers/DashboardReducers';
import {isFetchingReactionFormModel, reactionFormModel, isSavingReaction} from '../reducers/ReactionFormReducers';
import {isFetchingStatisticsModel, statisticsModel, statisticsMonthlyData, isFetchingStatisticsMonthlyData} from '../reducers/StatisticsReducers';
import {selectedPackage} from '../reducers/HomepageReducers';
import {isFetchingContentPageModel, isFetchingContentPagesMenuModel, contentPageModel} from '../reducers/ContentPagesReducers';
import {actionSuccessStatus} from '../reducers/ActionSuccessStatusReducers';
import {isFetchingGdprManagerModel, isConfirmingGdprAgreement, gdprManagerModel} from '../reducers/GdprReducers';
import {noCandidateAccessDialogOpen, noOrderAccessDialogOpen, notAllowedFeatureDialogOpen, allRecipientsWithoutEmailsDialog, someRecipientsWithoutEmailsDialog, isFetchingCandidatesForSomeRecipientsWithoutEmailsDialog, confirmCloseJobDialog, isFetchingCandidatesForConfirmCloseJobDialog, acceptedCandidateDialog, isFetchingCandidatesForAcceptedCandidateDialog, confirmCloseJobUncontactedDialog, cancelCloseJobWizardDialog, copyIntoTalentPoolDialogOpen, removeFromTalentPoolDialog, gdprAgreementFilterTestingDialog, gdprAgreementFilterTestingNewsletterDialog, noJobAccessDialogOpen, dashboardStatisticsTestingDialog, dashboardStatisticsTestingNewsletterDialog, dashboardNewsTestingDialog, dashboardNewsTestingNewsletterDialog} from '../reducers/DialogReducers';
import {allowTransition} from '../reducers/TransitionsPageReducers';
import {transitionLogic} from '../reducers/TransitionReducers';
import {companyEmailTemplatesModel, isFetchingCompanyEmailTemplatesModel} from '../reducers/CompanyEmailTemplatesReducers';
import {connectedUsersModel, isFetchingConnectedUsersModel} from '../reducers/ConnectedUsersReducers';
import {inactiveUsersModel, isFetchingInactiveUsersModel} from '../reducers/InactiveUsersReducers';
import {companyExportRequestsModel, isFetchingCompanyExportRequestsModel} from '../reducers/CompanyExportRequestsReducers';
import {candidatesGdprSettingsModel, isFetchingCandidatesGdprSettingsModel} from '../reducers/CandidatesGdprSettingsReducers';
import {chunkStatus} from '../reducers/ChunkStatusReducers';
import {isFetchingPipelineTemplateEditorModel, pipelineTemplateEditorModel} from '../reducers/PipelineTemplateEditorReducers';
import {isPageError, isErrorFocused} from '../reducers/ErrorReducers';
import {displaySize} from '../reducers/DisplaySizeReducers';
import {userActionsModel, isFetchingUserActionsModel} from '../reducers/UserActionReducers';
import {subscriptionsModel, isFetchingSubscriptionsModel} from '../reducers/SubscriptionReducers';
import {jobsGeneratorModel, isFetchingJobsGeneratorModel} from '../reducers/JobsGeneratorReducers';
import {jobQuestionnaireResultsModel, isFetchingJobQuestionnaireResultsModel} from '../reducers/JobQuestionnaireResultsReducers';
import {isCancellingScheduledEmail, scheduledEmailModel, isCancellingScheduledMassForwardEmail, scheduledMassForwardEmailModel} from '../reducers/ScheduledEmailReducers';
import {isSavingCandidatesListColumnsOrder, candidatesListColumnsOrderModel} from '../reducers/CandidatesListColumnsOrderReducers';
import {companyJobsModel, isFetchingCompanyJobsModel} from '../reducers/CompanyJobsReducers';
import {companyRecommendationsModel, isFetchingCompanyRecommendationsModel} from '../reducers/CompanyRecommendationsReducers';
import {isFetchingCandidateQuestionnaireModel, isSavingCandidateQuestionnaire, candidateQuestionnaireModel} from '../reducers/CandidateQuestionnaireReducers';
import {isFetchingRecommendationFormModel, isSavingRecommendation, recommendationFormModel} from '../reducers/RecommendationFormReducers';
import {currentlyAcceptedCandidates} from '../reducers/CurrentlyAcceptedCandidatesReducers';
import {closeJobModel, isFetchingCloseJobModel, isClosingJob} from '../reducers/CloseJobReducers';
import {talentPoolModel, isFetchingTalentPoolCandidates} from '../reducers/TalentPoolReducers';
import {chunkCancellation} from '../reducers/ChunkCancellationReducers';
import {isSavingUserOutOfOfficeEmail, isFetchingOutOfOfficeEmailModel, outOfOfficeEmailModel, isDeletingUserOutOfOfficeEmail} from '../reducers/OutOfOfficeEmailReducers';
import {internalInfoModel} from '../reducers/InternalInfoReducers';
import {isSavingUserTesting, isUpdatingUserTesting, userTestingModel} from '../reducers/UserTestingReducers';
import {dashboardModel as b2bDashboardModel, isFetchingDashboardModel as isFetchingB2bDashboardModel, isFetchingDashboardDataForUser as isFetchingB2bDashboardDataForUser, isFetchingDashboardDataForModal as isFetchingB2bDashboardDataForModal} from '../reducers/b2b/DashboardReducers';

const combinedReducers = combineReducers({
    chunkStatus,
    chunkCancellation,

    //DnD
    dragAndDropLogic,

    // Modal
    modalLogic,
    modalSecondLogic,
    noModalLogic,
    noModalSecondLogic,
    noModalDashboardLogic,

    //templateAssistant
    templateAssistant,
    isFetchingTemplatesForDropdownSelector,
    templatesForDropdownSelector,

    // Header
    headerModel,
    jobDetailHeader,
    backButton,
    isFetchingHeader,

    // Homepage
    selectedPackage,

    // Dashboard
    dashboardModel,
    isFetchingDashboardModel,
    isFetchingDashboardJobCandidates,
    dashboardJobCandidatesFetchingStatus,
    isFetchingDashboardDataForUser,
    isClosingJobs,
    isFetchingMoreEvents,

    // B2b Dashboard
    b2bDashboardModel,
    isFetchingB2bDashboardModel,
    isFetchingB2bDashboardDataForUser,
    isFetchingB2bDashboardDataForModal,

    // Login
    loginState,
    loginManagerModel,
    loginManagerModelBasic,
    isFetchingLoginManagerModel,
    isFetchingLoginManagerModelBasic,
    isCheckingCandidateFromEmail,
    candidateFromEmailCheckResult,

    // Candidate
    candidateModel,
    isFetchingCandidateTag,
    isFetchingCandidateNote,
    isFetchingCandidateHistory,
    isFetchingCandidateInbox,

    // Candidate sidebar
    sidebarEditPanelLogic,

    // JobsList
    jobsListModel,
    isFetchingJobsListModel,
    isFetchingJobsDetails,
    isRequestingJobsExport,

    // CreateCandidate
    createCandidateModel,
    isFetchingCreateCandidateModel,
    isSavingCandidate,

    // CopyCandidate
    candidateActionModel,
    isFetchingCopyCandidateModel,
    isFetchingMoveCandidateModel,
    isCopyingCandidates,
    isMovingCandidates,
    isSendingEmail,
    isChangingStageOfCandidates,
    isSchedulingCandidateEvent,
    isDeletingCandidateEvent,
    isFetchingCandidateEventModel,
    isFetchingCandidatesForAction,
    isAnonymizingCandidates,
    isFetchingChangeStageModel,
    isFetchingRefuseCandidatesModel,
    isRequestingCandidatesExport,
    isSavingCandidateGdprAgreement,
    isSendingCandidatesQuestionnaireRequest,
    isRemovingCandidateFromTalentPool,

    // CreateJob
    createJobModel,
    isFetchingCreateJobModel,
    isSavingJob,

    // CloseJob
    closeJobModel,
    isFetchingCloseJobModel,
    isClosingJob,

    // LiveSearch
    liveSearchModel,
    globalSearchModel,
    isFetchingGlobalSearchModel,
    isFetchingGlobalSearchData,
    isFetchingCandidates,
    isFetchingCandidateIds,

    // QuestionnaireEditor
    questionnaireEditorModel,
    isFetchingQuestionnaireEditorModel,

    // AutoAnswerEditor
    autoAnswerEditorModel,
    isFetchingAutoAnswerEditorModel,

    // Email
    sendEmailModel,
    previewEmailModel,
    isFetchingSendEmailModel,
    isFetchingPreviewEmailModel,
    isAddingNewColleagueContact,
    isRemovingColleagueContact,
    isGettingVideoInterviewData,

    // ScheduledEmail
    isCancellingScheduledEmail,
    scheduledEmailModel,
    isCancellingScheduledMassForwardEmail,
    scheduledMassForwardEmailModel,

    // Statistics
    statisticsModel,
    statisticsMonthlyData,
    isFetchingStatisticsModel,
    isFetchingStatisticsMonthlyData,

    // Job
    jobModel,
    isFetchingJobModel,
    isFetchingGetPositionData,
    isFetchingSourceTag,
    isFetchingCandidatesForJob,
    isUpdatingJobReactionForm,
    isSavingExternalUser,
    isRemovingExternalUser,
    isProcessingRecommendationTag,
    isApplyingDefaultPipelineTemplateToJob,
    isApplyingPipelineTemplateToJob,
    isCheckingStagesForTransform,
    isProcessingPipelineTemplateForJob,
    isFetchingCandidateForDetail,

    // Reaction
    reactionFormModel,
    isFetchingReactionFormModel,
    isSavingReaction,

    // Recommendation
    recommendationFormModel,
    isFetchingRecommendationFormModel,
    isSavingRecommendation,

    // Company Jobs
    companyJobsModel,
    isFetchingCompanyJobsModel,

    // Company Recommendations
    companyRecommendationsModel,
    isFetchingCompanyRecommendationsModel,

    // Candidate questionnaire
    isFetchingCandidateQuestionnaireModel,
    isSavingCandidateQuestionnaire,
    candidateQuestionnaireModel,

    // Gdpr
    isFetchingGdprManagerModel,
    isConfirmingGdprAgreement,
    gdprManagerModel,

    // Candidates filter
    candidateFilter,
    candidateListTable,

    // Attachment assistant
    attachmentAssistantModel,
    isFetchingAttachmentAssistantModel,
    isFetchingMultiAttachmentAssistantModel,

    // Questionnaire results
    questionnaireResultsModel,
    isFetchingQuestionnaireResults,

    // Settings
    settingsModel,
    isFetchingSettingsModel,
    isAuthenticatingGoogle,
    isAuthenticatingMicrosoft,
    isSettingCalendar,
    isSynchronizingCalendar,
    isSettingDefaultJobView,
    isSettingDefaultPublicNotes,
    isSettingEventToEmailForwarding,

    // QuestionnairesSettings
    questionnairesSettingsModel,
    isFetchingQuestionnairesSettings,
    isProcessingQuestionnaire,

    // AutoAnswersSettings
    autoAnswersSettingsModel,
    isFetchingAutoAnswersSettings,
    isProcessingAutoAnswer,

    // EmailTemplatesSettings
    emailTemplatesSettingsModel,
    isFetchingEmailTemplatesSettings,
    isProcessingEmailTemplate,

    // PipelineTemplatesSettings
    pipelineTemplatesSettingsModel,
    isFetchingPipelineTemplatesSettings,
    isProcessingPipelineTemplate,
    isSettingPipelineToOpenJobs,

    // CompanySettings
    companySettingsModel,
    companyPriceListModel,
    isFetchingCompanySettingsModel,
    isFetchingCompanyPriceListModel,
    isFetchingCompanyExportModel,
    isModifyingServices,
    isSavingCompanyCandidatesGdprExpirationSettings,

    // PipelineTemplateEditor
    isFetchingPipelineTemplateEditorModel,
    pipelineTemplateEditorModel,

    // ContentPage
    contentPageModel,
    isFetchingContentPagesMenuModel,
    isFetchingContentPageModel,

    // Toast Messages
    actionSuccessStatus,

    // Dialogs
    noCandidateAccessDialogOpen,
    noJobAccessDialogOpen,
    noOrderAccessDialogOpen,
    allRecipientsWithoutEmailsDialog,
    someRecipientsWithoutEmailsDialog,
    isFetchingCandidatesForSomeRecipientsWithoutEmailsDialog,
    notAllowedFeatureDialogOpen,
    confirmCloseJobDialog,
    isFetchingCandidatesForConfirmCloseJobDialog,
    acceptedCandidateDialog,
    isFetchingCandidatesForAcceptedCandidateDialog,
    confirmCloseJobUncontactedDialog,
    cancelCloseJobWizardDialog,
    copyIntoTalentPoolDialogOpen,
    removeFromTalentPoolDialog,
    gdprAgreementFilterTestingDialog,
    gdprAgreementFilterTestingNewsletterDialog,
    dashboardStatisticsTestingDialog,
    dashboardStatisticsTestingNewsletterDialog,
    dashboardNewsTestingDialog,
    dashboardNewsTestingNewsletterDialog,

    // Page Transition
    allowTransition,
    transitionLogic,

    // SidebarMenu
    sidebarHeaderLogic,

    // Admin
    companyEmailTemplatesModel,
    isFetchingCompanyEmailTemplatesModel,
    companyExportRequestsModel,
    isFetchingCompanyExportRequestsModel,
    candidatesGdprSettingsModel,
    isFetchingCandidatesGdprSettingsModel,
    userActionsModel,
    isFetchingUserActionsModel,
    subscriptionsModel,
    isFetchingSubscriptionsModel,
    jobsGeneratorModel,
    isFetchingJobsGeneratorModel,
    jobQuestionnaireResultsModel,
    isFetchingJobQuestionnaireResultsModel,
    connectedUsersModel,
    isFetchingConnectedUsersModel,
    inactiveUsersModel,
    isFetchingInactiveUsersModel,

    // Resolutions
    displaySize,

    // Error
    isPageError,
    isErrorFocused,

    // CandidatesListColumnsOrder
    isSavingCandidatesListColumnsOrder,
    candidatesListColumnsOrderModel,

    currentlyAcceptedCandidates,

    // Talent Pool
    talentPoolModel,
    isFetchingTalentPoolCandidates,

    // Out Of Office Email
    isSavingUserOutOfOfficeEmail,
    isFetchingOutOfOfficeEmailModel,
    outOfOfficeEmailModel,
    isDeletingUserOutOfOfficeEmail,

    //InternalInfoModel
    internalInfoModel,

    //User Testing
    isSavingUserTesting,
    userTestingModel,
    isUpdatingUserTesting,
});

const appStore = createStore(
    combinedReducers,
    applyMiddleware(
        loggerMiddleware,
        fetchMiddleware,
        fetchCandidatesInChunksMiddleware,
        postChunksMiddleware,
        toastMessagesMiddleware,
        authenticationMiddleware,
    ),
);

export default appStore;
